import React from "react";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import { useLocale } from "@insightfulscience/i18";

const Error503 = () => {
	const [t] = useLocale("503");

	return (
		<>
			<Heading
				Tag="h1"
				sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
				spacing={{ mb: 6 }}
			>
				{t("TITLE")}
			</Heading>
			<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>{t("DESC")}</Paragraph>
		</>
	);
};

export default Error503;
