import React, { Fragment } from "react";
import { object, oneOf, string, bool, shape } from "prop-types";
import Feature from "@insightfulscience/atomic-react/Feature";
import FeatureMeta from "@insightfulscience/atomic-react/Feature/Meta";
import FeatureImage from "@insightfulscience/atomic-react/Feature/Image";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Button from "@insightfulscience/atomic-react/Button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Template from "@insightfulscience/contentful/Apps/components/Template";
import WistiaVideoEmbed from "@insightfulscience/contentful/ui/WistiaVideoEmbed";
import richTextTypes from "@insightfulscience/contentful/richTextTypes/default";
import Heading from "@insightfulscience/atomic-react/Heading";

const VisualCopyBlock = props => {
	const {
		blockTitle,
		blockSubtitle,
		alignment = "Left",
		heading,
		lead,
		image,
		secondaryCtaText,
		secondaryCtaLink,
		ctaText,
		ctaLink,
		video,
		backgroundColor,
		internalSlug,
		withTemplate = true,
		blockTitleAlignedLeft = false,
	} = props;

	const isImgExist = !!image?.fields?.file?.url;
	const isIVideoExist = !!video?.fields?.videoUrl;
	const isHasActions = secondaryCtaText && secondaryCtaLink;

	const Wrapper = withTemplate ? Template : Fragment;
	const wrapperProps = withTemplate
		? {
				title: blockTitle,
				subtitle: blockSubtitle,
				backgroundColor,
				anchor: internalSlug,
				textAlign: blockTitleAlignedLeft ? "left" : "center",
		  }
		: {};

	return (
		<Wrapper {...wrapperProps}>
			<Feature px>
				{(alignment === "Right" && isIVideoExist && (
					<Col md={6} spacing={{ md: { pr: 10 }, lg: { pr: 10 } }}>
						<WistiaVideoEmbed url={video?.fields?.videoUrl} />
					</Col>
				)) ||
					(alignment === "Right" && isImgExist && !isIVideoExist && (
						<FeatureImage src={image?.fields?.file?.url} md={6} isBaseUrl={false} />
					))}

				<FeatureMeta md={6} indent={false}>
					{heading && (
						<Heading Tag="h2" sx={{ fontSize: "m", fontColor: "black" }} spacing={{ mb: 4 }}>
							{heading}
						</Heading>
					)}
					{lead && (
						<FeatureMeta.Description>
							{documentToReactComponents(lead, richTextTypes)}
						</FeatureMeta.Description>
					)}
					{isHasActions && (
						<FeatureMeta.Action>
							<Row>
								<Col>
									<Button.Link href={secondaryCtaLink} styleType="secondary" size="s" isOutline>
										{secondaryCtaText}
									</Button.Link>
								</Col>
							</Row>
						</FeatureMeta.Action>
					)}
				</FeatureMeta>
				{(alignment === "Left" && isIVideoExist && (
					<Col md={6} spacing={{ md: { pl: 10 }, lg: { pl: 10 } }}>
						<WistiaVideoEmbed url={video?.fields?.videoUrl} />
					</Col>
				)) ||
					(alignment === "Left" && isImgExist && !isIVideoExist && (
						<FeatureImage src={image?.fields?.file?.url} md={6} isBaseUrl={false} />
					))}
			</Feature>
			{ctaLink && ctaText && (
				<Row spacing={{ mt: 15 }}>
					<Col center>
						<Button.Link href={ctaLink} styleType="primary" size="s">
							{ctaText}
						</Button.Link>
					</Col>
				</Row>
			)}
		</Wrapper>
	);
};

VisualCopyBlock.propTypes = {
	internalSlug: string.isRequired,
	blockTitle: string,
	blockSubtitle: string,
	alignment: oneOf(["Left", "Right"]),
	heading: string,
	lead: object, // eslint-disable-line react/forbid-prop-types,
	image: shape({
		fields: shape({
			title: string,
			description: string,
			file: shape({
				url: string,
				fileName: string,
				contentType: string,
			}),
		}),
	}),
	secondaryCtaText: string,
	secondaryCtaLink: string,
	ctaText: string,
	ctaLink: string,
	backgroundColor: string,
	withTemplate: bool,
	blockTitleAlignedLeft: bool,
	video: shape({
		fields: shape({
			videoUrl: string,
		}),
	}),
};

export default VisualCopyBlock;
