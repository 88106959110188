import React from "react";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Link from "@insightfulscience/atomic-react/Link";
import { useLocale } from "@insightfulscience/i18";
import routes from "../routes";

const Error400 = () => {
	const [t] = useLocale("400");
	return (
		<>
			<Heading
				Tag="h1"
				sx={{ fontSize: "xl", fontColor: "3", textAlign: "center" }}
				spacing={{ mb: 6 }}
			>
				{t("BAD_REQUEST")}
			</Heading>
			<Paragraph sx={{ fontSize: "s", textAlign: "center" }}>
				{t("DESC")}
				<br /> <br />
				{t("PLEASE")}{" "}
				<Link sx={{ fontSize: "s" }} href={routes.external.support}>
					{t("CONTACT_US")}
				</Link>{" "}
				{t("FOR_FURTHER_ASSISTANCE")}.
			</Paragraph>
		</>
	);
};

export default Error400;
