/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useCallback } from "react";
import { arrayOf, bool, shape, string } from "prop-types";
import NextLink from "@insightfulscience/smart-react/NextLink";
import CardBlock from "@insightfulscience/atomic-react/CardBlock";
import Block from "@insightfulscience/atomic-react/Block";
import Picture from "@insightfulscience/atomic-react/Picture";
import Inline from "@insightfulscience/atomic-react/Inline";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Icon from "@insightfulscience/atomic-react/Icon";
import { CardBlockCol, CardBlockRow } from "@insightfulscience/atomic-react/CardBlock/Grid";
import { useLocale } from "@insightfulscience/smart-react/i18";
import TabTitle from "@insightfulscience/atomic-react/Tabs/Title";
import { TabCol } from "@insightfulscience/atomic-react/Tabs/Grid";
import Tabs from "@insightfulscience/atomic-react/Tabs";
import getContentProps from "@insightfulscience/contentful/utils/getContentProps";
import { hasHtmEnding } from "@insightfulscience/contentful/utils/url/preparePostLink";
import RichTextLink from "@insightfulscience/contentful/ui/RichTextLink";
import { CONTENT_TYPE } from "@insightfulscience/contentful/constants";
import { setDefaultCardBlockImage } from "@insightfulscience/contentful/utils/imagesAPI";

const FeaturedContentItems = ({ items = [], isImgFluid = false, withFilter = false, ...props }) => {
	const [tP] = useLocale("plugins");
	const [tB] = useLocale("blog");
	const [tR] = useLocale("resources");
	const [contentType, setContentType] = useState(CONTENT_TYPE.VIDEO);

	const filteredItems = useMemo(
		() => (withFilter ? items.filter(item => item.sys_type === contentType) : items),
		[withFilter, items, contentType],
	);

	const changeContentType = useCallback(type => () => setContentType(type), [setContentType]);

	return (
		<>
			{withFilter && (
				<Tabs center mx isBorderBottom={false} spacing={{ my: 12 }}>
					<TabCol md={3} onClick={changeContentType(CONTENT_TYPE.VIDEO)}>
						<TabTitle sx={{ fontWeight: "bold" }} active={contentType === CONTENT_TYPE.VIDEO}>
							{tR("VIDEOS")}
						</TabTitle>
					</TabCol>
					<TabCol md={3} onClick={changeContentType(CONTENT_TYPE.GUIDE)}>
						<TabTitle sx={{ fontWeight: "bold" }} active={contentType === CONTENT_TYPE.GUIDE}>
							{tR("GUIDES")}
						</TabTitle>
					</TabCol>
				</Tabs>
			)}
			{filteredItems.length > 0 && (
				<CardBlockRow center>
					{filteredItems.map((item, index) => {
						const {
							slug = "",
							externalUrl = "",
							teaser,
							lead,
							type,
							title,
							thumbnail,
							listThumbnails,
							articleType,
							image,
							listImages,
							sys_type: sysType,
							description: itemDescription,
							sys_id: sysId,
						} = item;
						const description = teaser || lead || itemDescription;
						const contentProps = getContentProps(sysType, type);
						const DefaultLinkComponent =
							contentProps && contentProps.alias ? NextLink : RichTextLink;
						const LinkComponent = hasHtmEnding(slug) ? RichTextLink : DefaultLinkComponent;
						// console.log('image', image)
						// console.log('thumbnail', thumbnail)
						switch (sysType) {
							case "pageBlog":
								return (
									<CardBlockCol key={`${sysId}_${index}`}>
										<CardBlock
											titleText={title}
											href={
												contentProps && contentProps.alias ? `${contentProps.alias}/${slug}` : "#"
											}
											isImgFluid
											imgSrc={thumbnail}
											imgSrcList={setDefaultCardBlockImage(listThumbnails)}
											descriptionText={lead}
											linkButtonText={tB("READ_MORE")}
											cardType={articleType}
											utility={{ bgColor: "white" }}
										/>
									</CardBlockCol>
								);
							case "pagePlugins":
								return (
									<CardBlockCol
										key={`${sysId}_${index}`}
										utility={{
											border: { width: 1, color: "dotmatics-1" },
											display: "flex-vertical",
											justify: "between",
										}}
									>
										<Block>
											<DefaultLinkComponent
												href={
													contentProps && contentProps.alias ? `${contentProps.alias}/${slug}` : "#"
												}
												sx={{ fontColor: "black", hover: { fontColor: "3" } }}
												utility={{ display: "block" }}
												className="link--underline-none"
											>
												<Picture
													isBaseUrl={false}
													src={thumbnail}
													srcList={setDefaultCardBlockImage(listImages)}
												/>
												<Block spacing={{ py: 3, px: 5 }} utility={{ display: "flex-vertical" }}>
													<Inline>{title}</Inline>
												</Block>
											</DefaultLinkComponent>
											<Paragraph
												spacing={{ mt: 0, mx: 5 }}
												sx={{ fontColor: "black--custom-1", fontSize: "s" }}
											>
												{description}
											</Paragraph>
										</Block>
										<Block spacing={{ mt: 3, my: 5, mx: 5 }}>
											<DefaultLinkComponent
												href={
													contentProps && contentProps.alias ? `${contentProps.alias}/${slug}` : "#"
												}
											>
												{tP("DOWNLOAD")} <Icon name="angle-right" color="3" />
											</DefaultLinkComponent>
										</Block>
									</CardBlockCol>
								);
							default:
								return (
									<CardBlockCol key={`${sysId}_${index}`}>
										<CardBlock
											isImgFluid={isImgFluid}
											LinkComponent={LinkComponent}
											cardType={contentProps?.typePlaceholder || type || ""}
											titleText={title}
											imgSrc={image}
											imgSrcList={setDefaultCardBlockImage(listImages)}
											descriptionText={description}
											href={
												contentProps && contentProps.alias
													? `${contentProps.alias}/${slug}`
													: externalUrl
											}
											linkButtonText={contentProps?.linkPlaceholder || ""}
											{...props}
										/>
									</CardBlockCol>
								);
						}
					})}
				</CardBlockRow>
			)}
		</>
	);
};

FeaturedContentItems.propTypes = {
	items: arrayOf(
		shape({
			sys_type: string,
			slug: string,
			externalUrl: string,
			teaser: string,
			description: string,
			title: string,
			image: string,
			type: string,
			lead: string,
			articleType: string,
			thumbnail: string,
		}),
	),
	isImgFluid: bool,
	imgClassName: string,
	withFilter: bool,
};

export default FeaturedContentItems;
