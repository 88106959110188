import Section from "@insightfulscience/atomic-react/Section";
import ContentContainer from "@insightfulscience/atomic-react/r1/atoms/ContentContainer";
import Link from "@insightfulscience/atomic-react/Link";
import React from "react";
import { arrayOf, shape, string } from "prop-types";

const BlockLinks = ({ linkReference }) => {
	return (
		<Section
			utility={{
				border: {
					top: {
						width: 1,
						color: "black--15",
					},
					bottom: {
						width: 1,
						color: "black--15",
					},
				},
			}}
			spacing={{ py: 8 }}
		>
			<ContentContainer lg={11} center>
				<div className="features-justify-links">
					{linkReference.map(({ fields }) => (
						<div className="features-justify-links__item" key={fields.linkUrl}>
							<Link sx={{ fontSize: "l" }} href={fields.linkUrl}>
								{fields.linkText}
							</Link>
						</div>
					))}
				</div>
			</ContentContainer>
		</Section>
	);
};

BlockLinks.propTypes = {
	linkReference: arrayOf(
		shape({
			fields: shape({
				linkText: string.isRequired,
				linkUrl: string.isRequired,
			}),
		}),
	),
};

export default BlockLinks;
