import React from "react";
import { WithLocale } from "@insightfulscience/i18";
import isInt from "@insightfulscience/shared-utils/parsers/isInt";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import errorPropType from "./error-prop-type";
import Error400 from "./Error400";
import Error401 from "./Error401";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Error500 from "./Error500";
import Error503 from "./Error503";
import ErrorDetails from "./ErrorDetails";
import ErrorLayout from "./ErrorLayout";
import mapGraphQLErrors from "./mapGraphQLErrors";
import resources from "../../locales/errors-page";

const errorComponents = {
	400: Error400,
	401: Error401,
	403: Error403,
	404: Error404,
	503: Error503,
};

const AppError = ({ error }) => {
	const { code } = error;
	const ErrorComponent = errorComponents[code] || Error500;

	return (
		<ErrorLayout extra={<ErrorDetails error={error} />}>
			<ErrorComponent error={error} />
		</ErrorLayout>
	);
};

AppError.getInitialProps = context => {
	const { err, res } = context;
	const appErr = mapGraphQLErrors(err);
	const errorCode = isInt(appErr.code) ? appErr.code : 500;

	if (res) {
		res.statusCode = errorCode;
	}

	if (appErr.code >= 500 && appErr.code !== 503 && appErr.originalError != null)
		throw appErr.originalError;

	return { error: appErr.toSerializable() };
};

AppError.propTypes = {
	error: errorPropType,
};

export default pipe(AppError, WithLocale({ resources }));
