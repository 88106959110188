import { compose, idX } from "@insightfulscience/shared-utils/fn";
import { withForceError } from "@insightfulscience/smart-react/ForceErrorHoC";
import AppError from "./AppError";
import withErrorHandlingHoC from "./withErrorHandlingHoC";
import { publicConfig } from "../config";

export const withErrorHandling = compose(
	withErrorHandlingHoC,
	publicConfig.DEBUG_MODE ? withForceError("throw") : idX,
);

export default AppError;
