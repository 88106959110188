import React from "react";
import * as Sentry from "@sentry/react";
import "nprogress/nprogress.css";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import AssetsBaseUrlProvider from "@src/AssetsBaseUrlProvider";
import "@src/next-router-events";
import "../styles/x-hidden-scroll.css";
import "../styles/hubspot.css";
import { initSentry } from "@src/sentry";
import RouterProvider from "@insightfulscience/atomic-react/utils/RouterProvider";
import { useRouter } from "next/router";
import "../src/feature-flags";

initSentry();

// useLocale.effectHook = useEffect;

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps }) => {
	const { push } = useRouter();

	return (
		<AssetsBaseUrlProvider>
			<RouterProvider routerPush={push}>
				<Component {...pageProps} />
			</RouterProvider>
		</AssetsBaseUrlProvider>
	);
};

export default pipe(App, Sentry.withProfiler);
