/* eslint-disable no-nested-ternary, prettier/prettier */
import ApplicationError from "@insightfulscience/smart-react/ApplicationError";

export const isGraphQLErrorIn = (...codes) => ({ graphQLErrors }) =>
	graphQLErrors && graphQLErrors.length && codes.includes(graphQLErrors[0].code);

export const isBadRequest = isGraphQLErrorIn(400, "INTERNAL_SERVER_ERROR");

export const isNotAuthorized = isGraphQLErrorIn(401);

export const isNotFound = isGraphQLErrorIn(400, 404);

export const isNotPermitted = isGraphQLErrorIn(403);

export const isServiceUnavailable = isGraphQLErrorIn(503);

export const isNetwork = ({ networkError }) => networkError != null;

const mapGraphQLErrors = error =>
	isBadRequest(error) ? new ApplicationError(error, 400) :
	isNotAuthorized(error) ? new ApplicationError(error, 401) :
	isNotPermitted(error) ? new ApplicationError(error, 403) :
	isNotFound(error) ? new ApplicationError(error, 404) :
	isServiceUnavailable(error) ? new ApplicationError(error, 503) :
	isNetwork(error) ? new ApplicationError(error) : 
	ApplicationError.of(error);

export default mapGraphQLErrors;