import React from "react";
import PageNavbar from "@insightfulscience/atomic-react/PageNavbar";
import PageNavbarItem from "@insightfulscience/atomic-react/PageNavbar/Item";
import Dropdown from "@insightfulscience/atomic-react/Dropdown";
import DropdownMenu from "@insightfulscience/atomic-react/Dropdown/Menu";
import DropdownMenuItem from "@insightfulscience/atomic-react/Dropdown/MenuItem";
import Inline from "@insightfulscience/atomic-react/Inline";
import { arrayOf, number, shape, string } from "prop-types";
import getContentProps from "@insightfulscience/contentful/utils/getContentProps";

const HeroIconSection = ({ items = [] }) => {
	return (
		items.length > 0 && (
			<PageNavbar
				alignHorizontal="around"
				sticky
				bgColor="2"
				utility={{ sm: { display: "none" }, md: { display: "none" } }}
			>
				{items.map(item => (
					<PageNavbarItem spacing={{ md: { py: 3 } }} key={item.id}>
						<Dropdown>
							<Dropdown.Title
								childrenProps={{
									utility: { display: "flex", align: { items: "center" } },
									sx: { fontColor: "white", fontSize: "l", hover: { fontColor: "3" } },
								}}
								triangleProps={{ size: "xl" }}
							>
								<Inline>{item.name}</Inline>
							</Dropdown.Title>
							<Dropdown.Body utility={{ bgColor: "transparent" }}>
								<DropdownMenu shadow={false}>
									<DropdownMenuItem key={item.id}>
										<DropdownMenuItem.Link
											className="text-color-2 text-color-white--hover"
											href={`#${item.id}`}
										>
											View All
										</DropdownMenuItem.Link>
									</DropdownMenuItem>

									{(item.contentReference || []).map(subItem => {
										const { slug = "", externalUrl = "" } = subItem.fields;
										const contentProps = getContentProps(subItem?.sys?.contentType?.sys?.id);

										return (
											<DropdownMenuItem key={subItem.sys.sys_id}>
												<DropdownMenuItem.Link
													className="text-color-2 text-color-white--hover"
													href={
														contentProps && contentProps.alias
															? `${contentProps.alias}/${slug}`
															: externalUrl
													}
												>
													{subItem.fields.title}
												</DropdownMenuItem.Link>
											</DropdownMenuItem>
										);
									})}
								</DropdownMenu>
							</Dropdown.Body>
						</Dropdown>
					</PageNavbarItem>
				))}
			</PageNavbar>
		)
	);
};

HeroIconSection.propTypes = {
	items: arrayOf(
		shape({
			blockOrder: number,
			id: string,
			name: string,
			items: arrayOf(
				shape({
					title: string,
					url: string,
					externalUrl: string,
					slug: string,
					type: string,
					sys_id: string,
				}),
			),
		}),
	),
};

export default HeroIconSection;
