import React from "react";
import LogoLink from "@insightfulscience/atomic-react/LogoLink";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "./routes";

export const logoPath = "/images/snapgene/logo/logo-snapgene-dotmatics.svg";
export const logoAlt = "SnapGene by Dotmatics";

const prerenderedErrorLogo = (
	<NextLink
		Component={LogoLink}
		href={routes.home.url}
		imgSrc={logoPath}
		alt={logoAlt}
		data-qa-selector="header-logo"
	/>
);

export default prerenderedErrorLogo;
