import React, { useMemo } from "react";
import { array, node, string } from "prop-types";

import HeroBrand from "@insightfulscience/contentful/ui/HeroBrand";
import HeroTitleSectionSnapgene from "./TitleSection/index";
import HeroIconSection from "./IconSection/index";
import heroConfig from "./heroConfig";

const SectionHero = ({ title, lead, brandCode, contentReference = [] }) => {
	const iconsInside = heroConfig.iconsInside[brandCode];

	const items = contentReference
		.map(item => item?.sys?.contentType?.sys?.id === "blockFeaturedContent" && { ...item.fields })
		.filter(item => !!item);

	const sortSections = (a, b) => {
		const sectionA = a.blockOrder;
		const sectionB = b.blockOrder;
		return sectionA < sectionB ? -1 : sectionA > sectionB ? 1 : 0; // eslint-disable-line
	};

	const listModified = useMemo(() => items.sort(sortSections), [items, sortSections]);

	return (
		<>
			<HeroBrand brandCode={brandCode} config={heroConfig}>
				<HeroTitleSectionSnapgene title={title} description={lead} />
				{iconsInside && <HeroIconSection items={listModified} />}
			</HeroBrand>
			{!iconsInside && <HeroIconSection items={listModified} />}
		</>
	);
};

SectionHero.propTypes = {
	title: string.isRequired,
	lead: node.isRequired,
	brandCode: string.isRequired,
	contentReference: array, // eslint-disable-line react/forbid-prop-types
};

export default SectionHero;
