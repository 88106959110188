import React from "react";
import { node } from "prop-types";
import HeroDescription from "@insightfulscience/atomic-react/Hero/Description";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Image from "@insightfulscience/atomic-react/Image";

const logo = "/images/common/new-snapgene-academy-logo-dark.png";

const HeroTitleSection = ({ description }) => {
	return (
		<>
			<Row center>
				<Col lg={7} sm={12}>
					<Image src={logo} />
				</Col>
			</Row>
			<HeroDescription>{description}</HeroDescription>
		</>
	);
};

HeroTitleSection.propTypes = {
	description: node,
};

export default HeroTitleSection;
