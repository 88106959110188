import { arrayOf, number, shape, string } from "prop-types";

export default shape({
	code: number,
	message: string,
	stack: arrayOf(string),
	originalError: shape({
		name: string,
		code: number,
		message: string,
	}),
});
