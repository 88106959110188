import React from "react";
import { array, elementType, shape, string } from "prop-types";
import { mapItems } from "@insightfulscience/contentful/Apps/components/FeaturedContentBlock/mapper";
import Template from "./Template";
import FeaturedContentItems from "./FeaturedContentItems";

const FeaturedContentBlock = ({
	id,
	name,
	subname,
	contentReference = [],
	components,
	...props
}) => {
	const SectionTemplate = components?.sectionTemplate || Template;
	return (
		<SectionTemplate title={name} subtitle={subname} anchor={id}>
			<FeaturedContentItems items={mapItems(contentReference)} {...props} />
		</SectionTemplate>
	);
};

FeaturedContentBlock.propTypes = {
	slug: string,
	id: string.isRequired,
	name: string.isRequired,
	subname: string,
	contentReference: array, // eslint-disable-line react/forbid-prop-types
	imgClassName: string,
	components: shape({
		sectionTemplate: elementType,
	}),
};

export default FeaturedContentBlock;
