import { useRouter } from "next/router";
import useGlobalContex from "../global-context/hook";

/** @type {() => string} */
function useCurrentPath() {
	const context = useGlobalContex();
	const router = useRouter();

	const { asPath } = router || context;

	return asPath;
}

export default useCurrentPath;
