import React from "react";
import { bool, node, oneOf, shape, string } from "prop-types";
import LandingSimpleSectionStripped from "@insightfulscience/atomic-react/LandingElement/SimpleSectionStripped";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";

const Template = ({
	title,
	subtitle,
	backgroundColor,
	anchor,
	children,
	textAlign = "center",
	containerProps,
}) => {
	return (
		<LandingSimpleSectionStripped
			bgColor={backgroundColor}
			containerProps={containerProps}
			spacing={{ py: 0, pt: 12 }}
			id={anchor}
		>
			{title && (
				<LandingSimpleSection.Title sx={{ textAlign }} spacing={{ mt: 4 }}>
					{title}
				</LandingSimpleSection.Title>
			)}
			{subtitle && (
				<LandingSimpleSection.DescriptionV2 sx={{ textAlign }}>
					{subtitle}
				</LandingSimpleSection.DescriptionV2>
			)}
			<LandingSimpleSection.Body>{children}</LandingSimpleSection.Body>
		</LandingSimpleSectionStripped>
	);
};

Template.propTypes = {
	title: string,
	subtitle: string,
	backgroundColor: string,
	anchor: string,
	children: node.isRequired,
	textAlign: oneOf(["left", "center"]),
	containerProps: shape({
		sm: oneOf(["", "auto", "shrink", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
		md: oneOf(["", "auto", "shrink", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
		lg: oneOf(["", "auto", "shrink", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
		center: bool,
	}),
};

export default Template;
