import React from "react";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import LandingSimpleSection from "@insightfulscience/atomic-react/r2/LandingElement/SimpleSection";
import { useLocale, WithLocale } from "@insightfulscience/i18";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import SiteLayout from "@src/SiteLayout";
import SectionTryForFree from "@src/SectionTryForFree";
import AppError, { withErrorHandling } from "@src/AppError";
import contentfulStandardApp from "@insightfulscience/contentful/Apps/Standard";
import BlockLinks from "@src/Contentful/BlockLinks";
import VisualCopyBlock from "@src/Contentful/VisualCopyBlock";
import ResourcesSectionHero from "@src/Contentful/ResourcesSectionHero";
import FeaturedContentBlock from "@src/Contentful/FeaturedContentBlock/index";
import resources from "../../locales/resources-page";

const resourcesLayout = ({ children }) => {
	const [t] = useLocale("resources");

	return (
		<SiteLayout hero>
			<PageMeta>
				<PageMeta.OGP
					image={t("META_IMAGE")}
					imageSecureUrl={t("META_IMAGE_SECURE")}
					isImageBaseUrl
				/>
				<PageMeta.Twitter image={t("META_TWITTER_IMAGE")} isImageBaseUrl />
			</PageMeta>
			{children}
			<LandingSimpleSection id="faqs" isBorderNone>
				<LandingSimpleSection.Title>{t("USER_GUIDES")}</LandingSimpleSection.Title>
				<LandingSimpleSection.Action
					href="https://support.snapgene.com/hc/en-us/categories/10304176221716"
					size="l"
					weight="bold"
				>
					{t("MOST_POPULAR_QUESTIONS")}
				</LandingSimpleSection.Action>
			</LandingSimpleSection>
			<SectionTryForFree />
		</SiteLayout>
	);
};

export default pipe(
	contentfulStandardApp({
		Layout: pipe(resourcesLayout, WithLocale({ resources })),
		AppError,
		contentfulProps: {
			environment: process.env.CTF_ENV,
			space: process.env.CTF_SPACE,
			accessToken: process.env.CTF_TOKEN,
			proxy: null,
		},
		pageProps: {
			brandCode: "sg",
			siteDomain: "https://www.snapgene.com",
			resources,
			components: {
				linksBlock: BlockLinks,
				visualCopyBlock: VisualCopyBlock,
				heroBlock: ResourcesSectionHero,
				blockFeaturedContent: FeaturedContentBlock,
			},
		},
	}),
	withErrorHandling,
);
