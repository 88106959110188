import React from "react";
import ContentContainer from "@insightfulscience/atomic-react/r1/atoms/ContentContainer";
import { bgColorBlack, colorWhite } from "@insightfulscience/atomic-react/utils/color";
import { publicConfig } from "../config";
import errorPropType from "./error-prop-type";

const ErrorDetails = ({ error }) => (
	<ContentContainer mx md={10} lg={8} center>
		<div className={`panel ${bgColorBlack}`}>
			<pre className={`${colorWhite} px-5 py-5`}>{JSON.stringify(error, null, 2)}</pre>
		</div>
	</ContentContainer>
);

ErrorDetails.propTypes = {
	error: errorPropType,
};

export default publicConfig.DEBUG_MODE ? ErrorDetails : () => null;
