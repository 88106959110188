import React from "react";
import { node } from "prop-types";
import Layout from "@insightfulscience/atomic-react/Layout";
import SimpleTemplate from "@insightfulscience/atomic-react/SimpleTemplate";
import Header from "@insightfulscience/atomic-react/Header";
import Section from "@insightfulscience/atomic-react/Section";
import { bgColorBlack2 } from "@insightfulscience/atomic-react/utils/color";
import prerenderedErrorLogo from "../prerendered-error-logo";

/** @type {React.FC<{ children: React.ReactNode, extra: React.ReactNode }>} */
const ErrorLayout = ({ children, extra }) => (
	<Layout>
		<Layout.Header>
			<Header logo={prerenderedErrorLogo} />
		</Layout.Header>
		<Layout.Body className={bgColorBlack2}>
			<SimpleTemplate>{children}</SimpleTemplate>
			{extra && <Section className="pt-9 pt-9--md pb-18 pb-18--md">{extra}</Section>}
		</Layout.Body>
	</Layout>
);

ErrorLayout.propTypes = {
	children: node,
	extra: node,
};

export default ErrorLayout;
