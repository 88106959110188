const heroConfig = {
	color: {
		sg: "black--02",
	},
	iconsInside: {
		sg: false,
	},
};

export default heroConfig;
